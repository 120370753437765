
<template>
  <v-container fluid>
    <page-header title='Resumo'></page-header>
    <dialog-loading v-if="loading"></dialog-loading>
      <v-row cols="12" lg="12" class="">
          <v-col cols="12" lg="4">
            <waiting-selection/>
          </v-col>
          <v-col cols="12" lg="4">
            <enrolled-courses></enrolled-courses>
          </v-col>
          <v-col cols="12" lg="4">
            <unanswered-forms :user_id="user.id"></unanswered-forms>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'


export default {
  name: 'StudentHome',
  components: {
      WaitingSelection: () => import('../courses/student_courses/WaitingSelection.vue'),
      EnrolledCourses: () => import('../courses/student_courses/EnrolledCourses.vue'),
      UnansweredForms: () => import('../forms/unanswered/UnansweredForms.vue'),
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user: state=> state.User.user
    })
  },
}
</script>